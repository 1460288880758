*{
  box-sizing:border-box
  }
  
  body{
  margin: 0;
  padding: 0;
  font-family: "Poppins";
  background-color:(rgb(160, 199, 212)) !important;
  }
  
  form{
  display: flexbox;
  border-radius:20px;
  width: 100%;
  }
span{
  color: #135AC8
}


.fixbot{
  position: absolute;
  bottom: 10px;
}

.LoginPage{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-image: url(./images/login-background.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow-y:hidden !important ;
}
.login-container{
  border-radius: 50px 0px 0px 50px;
  background-color: #135AC8;
}
.login-right-container{
  border-radius: 0px 50px 50px 0px;
  background-color: #CECECE;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-textfield{
  border: 1px solid white !important;
  border-radius: 3px;
}
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input:autofill{
  color: white;
}


